<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;">修改密码</a>
        </div>
      </div>
      <div class="personal per_info pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status">发票管理</router-link>
          <!-- mao 20250324 -->
          <!-- <router-link to="/contract_management">合同管理</router-link> -->
          <!-- end -->
          <router-link to="/change_password" class="active">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">修改密码</div>
          <div class="flex-box pb30">
            <div class="fs16 title">新密码</div>
            <input type="password" v-model="user.Password" class="phone fs16" placeholder="请输入新密码" />
          </div>
          <div class="flex-box pb30">
            <div class="fs16 title">确认密码</div>
            <input type="password" v-model="user.newPassword" class="phone fs16" placeholder="请输入确认密码" />
          </div>
          <div class="save fs16" @click="submit">保存</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import cookie from "vue-cookies";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      user: {
        Password: "",
        newPassword:''
      },
    };
  },
  methods: {
    submit(){
      let that = this;
      let user = that.user
      if(user.Password==''){
        that.$message.error("请输入新密码！");
        return;
      }
      if(user.newPassword==''){
        that.$message.error("请输入确认密码！");
        return;
      }
      if(user.Password != user.newPassword){
        that.$message.error("两次密码输入不一致！");
        return; 
      }
      if(user.Password.length < 8 || user.Password.length >30){
        that.$message.error("请输入8-30位的密码！");
        return;
      }
      var regex = new RegExp('^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,30}$');
      if(!regex.test(user.Password)){
       that.$message.error("密码可使用数字、大写字母、小写字母及特殊字符，至少包含三种字符");
       return; 
     }
     let userinfo = cookie.get("huanbaoqiao_userinfo");
     that.$api.updateUserInfo({id:userinfo.id,password:user.Password}).then((res) => {
        if (res.resCode == 0) {
          that.$message.success('密码更改成功，请重新登录');
          cookie.remove("huanbaoqiao_token");
          cookie.remove("huanbaoqiao_userinfo");
          setTimeout(function () {
            that.$router.push("/login");
          }, 800)
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },


    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id}).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },
  }
};
</script>
